import validator from "validator";
import api from "../Services/api";
import axios from "axios";
import { _fetchProducts } from "./ProductManager";
import { _GetBlogs } from "./BlogManager";
import { storage } from "../Containers/Firebase/Firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { _apiCalls, _apiGetCalls } from "./ApiCalls";
import { Months } from "../Data/dataArray";

export const _requestForslot = async (mentorid, dispatch) => {
  dispatch({
    type: "site_Loading",
    payload: true,
  });
  try {
    let data = {
      mentorid: mentorid,
    };
    const request = await _apiCalls("/requestForslot", data);
    dispatch({
      type: "site_Loading",
      payload: false,
    });
  } catch (err) {
    dispatch({
      type: "site_Loading",
      payload: false,
    });
  }
};

export const _GetMentor = async (dispatch) => {
  dispatch({
    type: "site_Loading",
    payload: true,
  });
  try {
    const request = await _apiGetCalls("/getmentors");

    dispatch({
      type: "mentors",
      payload: request.data,
    });

    dispatch({
      type: "site_Loading",
      payload: false,
    });
  } catch (err) {
    dispatch({
      type: "site_Loading",
      payload: false,
    });
  }
};

export const _firebaseUploads = async (folder, file, user) => {
  return new Promise(async (resolve, reject) => {
    const storageRef = ref(storage, `${folder}/${user._id}`);
    uploadBytes(storageRef, file)
      .then((snapshot) => {
        const gsReference = ref(
          storage,
          `gs://skillkartnewapp.appspot.com/${folder}/${user._id}`
        );
        getDownloadURL(gsReference).then((result) => {
          return resolve(result);
        });
      })
      .catch((error) => {
        return reject(error);
      });
  });
};

export const _getItemFromFirebase = async (folder, user) => {
  const gsReference = ref(
    storage,
    `gs://skillkartnewapp.appspot.com/${folder}/${user?._id}`
  );
  return new Promise(async (resolve, reject) => {
    return await getDownloadURL(gsReference)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject();
      });
  });
};

export const _getUserGeoLocation = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      await axios
        .get("https://geolocation-db.com/json/")
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject();
        });
    } catch (err) {
      reject();
    }
  });
};

export const _getDatefromCurrentDate = () => {
  let date = new Date();
  let currentyear = date.getFullYear() + 5;
  let array = [];
  for (let i = 30; i > 0; i--) {
    currentyear -= 1;
    array.push(currentyear);
  }
  return array.map((dates) => {
    return (
      <option value={dates} className="text-black">
        {dates}
      </option>
    );
  });
};

export const _siteDatafetching = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const product = await _fetchProducts()
        .then((result) => {
          return result;
        })
        .catch(() => {
          return [];
        });
      const blogs = await _GetBlogs()
        .then((result) => {
          return result;
        })
        .catch(() => {
          return [];
        });
      resolve({ product, blogs });
    } catch (err) {
      reject(err);
    }
  });
};

export const calcuateResult = (airesult, booking) => {
  let result = airesult.filter((state) => state.roomid == booking.roomid)[0];

  let sumRanacorus = 0;
  let sumNervousness = 0;
  let sumEnthusiasm = 0;
  let sumConfidence = 0;
  let sumDepress = 0;

  result?.expression?.forEach((entry) => {
    sumRanacorus += entry?.Ranacorus;
    sumNervousness += entry?.Nervousness;
    sumEnthusiasm += entry?.Enthusiasm;
    sumConfidence += entry?.Confidence;
    sumDepress += entry?.Depress;
  });

  const averageRanacorus = sumRanacorus / result?.expression?.length;
  const averageNervousness = sumNervousness / result?.expression?.length;
  const averageEnthusiasm = sumEnthusiasm / result?.expression?.length;
  const averageConfidence = sumConfidence / result?.expression?.length;
  const averageDepress = sumDepress / result?.expression?.length;

  let maxValue = Math.max(
    averageRanacorus,
    averageNervousness,
    averageEnthusiasm,
    averageConfidence,
    averageDepress
  );

  return (
    <div className="w-full">
      <div className="my-2 w-full py-2 flex items-center justify-start">
        <div className="rounded text-sm font-bold">Ai Result</div>
      </div>
      <div className="flex items-center justify-center text-xs">
        <div className="flex flex-col-reverse items-center justify-center mx-5">
          <div className="text-sm  mt-2">Ranacorus</div>
          <div className="mt-2 ">
            <div className="w-12 h-12 font-semibold rounded-full border border-black flex items-center justify-center">
              {Math.ceil((averageRanacorus / maxValue) * 100)}%
            </div>
          </div>
        </div>
        <div className="flex flex-col-reverse items-center justify-center mx-5">
          <div className="text-sm ">Enthusiasm</div>
          <div className="mt-2 ">
            <div className="w-12 h-12 font-semibold rounded-full border border-black flex items-center justify-center">
              {Math.ceil((averageEnthusiasm / maxValue) * 100)}%
            </div>
          </div>
        </div>
        <div className="flex flex-col-reverse items-center justify-center mx-5">
          <div className="text-sm ">Confidence</div>
          <div className="mt-2 ">
            <div className="w-12 h-12 font-semibold rounded-full border border-black flex items-center justify-center">
              {Math.ceil((averageConfidence / maxValue) * 100)}%
            </div>
          </div>
        </div>
        <div className="flex flex-col-reverse items-center justify-center mx-5">
          <div className="text-sm">Nervousness</div>
          <div className="mt-2 ">
            <div className="w-12 h-12 font-semibold rounded-full border border-black flex items-center justify-center">
              {Math.ceil((averageNervousness / maxValue) * 100)}%
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const getDateofExpire = (purchasdate) => {
  let purchaseDate = new Date(purchasdate);

  let futureDate = new Date(purchaseDate);
  futureDate.setDate(purchaseDate.getDate() + 59);
  return `${futureDate.getDate().toString().padStart(2, "0")}${" "}${
    Months[parseInt(futureDate.getMonth() + 1)]
  }  ${"  "} ${"  "}${futureDate.getFullYear()}`;
};
