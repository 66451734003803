import React, { useEffect, useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { _PurchaseCourse } from "../../../Services/ProductManager";
import {
  ActivePack,
  IsLoading,
  User,
} from "../../../Containers/Redux/Selectors";

function Products({ product }) {
  const user = User();
  const isLoading = IsLoading();
  const activePack = ActivePack();
  const [purchased, setPurchased] = useState(false);
  const [benefit, setBenefit] = useState([
    "Practice with Industry mentors",
    " Personalized feedback for each session",
    " Get Insight into your academics",
    "  Get Insight on the modules you need to prepare for clear HR and Technical rounds",
    " AI tool to give you complete behaviour analysis",
  ]);
  useEffect(() => {
    setPurchased(
      activePack?.filter((state) => state?.course_id == product?._id)[0]
    );
  }, []);
  return (
    <div className="w-1/3 min-h-0 max-[950px]:w-1/2 max-[620px]:w-full border-l border-b border-l-white/20 border-b-white/20">
      <div className="w-full bg-[#0a0a0a] rounded-lg">
        <div className="w-full flex items-center flex-col relative">
          <div className="mt-4 mx-1 w-full px-4">
            <div className="text-xl font-bold px-2 pt-3 text-white/90">
              {product.product_name}
            </div>
            <div className="text-2xl font-semibold px-2 pb-3 pt-1 text-white/70">
              ₹{product.discount_pricing}{" "}
              <span className="text-sm text-white/90">
                / {product.total_session} sessions
              </span>
            </div>
          </div>
          <div className="px-4 w-full">
            <ul className="w-full">
              {benefit.map((ben, index) => (
                <li className="flex items-start my-3 w-full" key={index}>
                  <span className="ml-2 w-4/5 text-base text-white/60">
                    {ben}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <div className="w-full flex items-center py-4 justify-center">
            {isLoading ? (
              <div className="bg-white h-12 w-11/12 rounded-md font-semibold flex items-center justify-center text-lg">
                Loading....
              </div>
            ) : (
              <>
                {product.activate ? (
                  <>
                    <div className="w-full">
                      {purchased ? (
                        <div className="flex items-center justify-center w-full px-3">
                          <button
                            onClick={() => {
                              window.location.assign(`/dashboard`);
                            }}
                            className="border-2  text-white border-white cursor-pointer h-12 w-11/12 rounded-md font-semibold flex items-center justify-center text-lg"
                          >
                            Go To Course
                          </button>
                        </div>
                      ) : (
                        <div className="flex items-center justify-center w-full px-3">
                          <div
                            onClick={() => _PurchaseCourse(product, user)}
                            className="bg-[#ff9d00] text-black cursor-pointer h-10 rounded-full font-semibold flex items-center justify-center text-base w-1/2 mx-1"
                          >
                            Enroll Now
                          </div>
                          <div className="bg-white text-black cursor-pointer h-10 rounded-full font-semibold flex items-center justify-center text-base w-1/2 mx-1">
                            Contact us
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <div className="flex items-center justify-center w-full px-3">
                    <div className="bg-[#f7dc6f] text-black cursor-pointer h-10 rounded-full font-semibold flex items-center justify-center text-base w-1/2 mx-1">
                      Waitlist
                    </div>
                    <div className="bg-white text-black cursor-pointer h-10 rounded-full font-semibold flex items-center justify-center text-base w-1/2 mx-1">
                      Contact us
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Products;
