import React, { useState } from "react";
import resume from "../../Assets/images/resumeanalysis.svg";
import behaviour from "../../Assets/images/behaviour.svg";
import Technology from "../../Assets/images/Technology.svg";

function Assessment() {
  const [assessments, setAssessments] = useState([
    {
      title: "Resume Analysis",
      description:
        "A well-crafted resume is crucial for job success, serving as your first impression with potential employers. Our analysis helps you create a strong, effective resume that highlights your journey and boosts your chances for interviews.",
    },
    {
      title: "Behavioral Skill Analysis",
      description:
        "Success in any career requires strong interpersonal skills, emotional intelligence, and the ability to handle various situations and interviews. Our program helps you navigate professional interactions and develop strategies for different interview scenarios.",
    },
    {
      title: "Technical Skill Analysis",
      description:
        "Achieving long-term career goals requires a solid understanding of technical fundamentals and industry trends. Our analysis guides you in strengthening your technical skills and preparing for future job expectations.",
    },
  ]);

  return (
    <div className="bg-[#15181a] w-full py-28 px-16 flex flex-col max-md:px-4 max-sm:px-2 max-lg:py-14">
      <div className="text-6xl max-lg:w-full mt-4 pb-4 pt-1 text-left max-sm:w-full text-white w-2/3  font-bold max-md:text-3xl">
        Personalized Growth through Skill and Progress Assessment
      </div>
      <div className="text-white/70 w-2/3  max-lg:w-full">
        Assessing your skills, knowledge, and progress provides valuable
        insights, allowing for tailored feedback and growth strategies that
        foster continuous improvement and refinement.
      </div>
      <div className="w-full flex flex-wrap mt-24">
        {assessments.map((assessmant) => {
          return (
            <div className="w-1/3 max-lg:w-full pr-10 max-lg:mb-6">
              <div className="w-3/5 h-0.5 bg-[#ff9d00]"></div>
              <div className="text-white font-bold text-2xl mt-3">
                {assessmant.title}
              </div>
              <div className="mt-3 text-white/70 text-lg text-justify">
                {assessmant.description}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Assessment;
