import React from "react";
import { useSelector } from "react-redux";
import { courses } from "../../../Data/dataArray";
import Products from "./Products";

function ProductShowCase() {
  const isloading = useSelector((state) => state.isFetchingData);

  return (
    <div className="bg-black py-24 w-full px-32 flex items-center justify-center max-lg:px-0">
      <div className="max-[950px]:w-full border border-white/10 py-12">
        <div className="flex flex-col items-center max-sm:mx-0">
          <div className=" px-12 w-full py-7 text-white/90 text-center  border-y border-y-white/10 border-dashed ">
            {" "}
            <span className="text-4xl font-semibold max-[960px]:text-3xl">
              Find the perfect program to enhance your skills.
            </span>
            <div className="text-lg mt-2 text-white/60 font-normal">
              Let Our Expert Guidance Propel You Towards Success in the
              Competitive Job Market.
            </div>
          </div>

          <div className="flex items-center justify-left flex-wrap w-full my-8 border-y border-solid border-y-white/10">
            {isloading ? (
              <div className=" w-full flex items-center">
                <div className="w-1/3 mx-3 h-64 productloading flex items-center justify-center">
                  Loadign Product..
                </div>
                <div className="w-1/3 mx-3 h-64 productloading flex items-center justify-center">
                  Loadign Product..
                </div>
                <div className="w-1/3 mx-3 h-64 productloading flex items-center justify-center">
                  Loadign Product..
                </div>
              </div>
            ) : (
              <>
                {courses?.map((product, index) => (
                  <Products product={product} />
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductShowCase;
