export const hasFutureDateWithTime = (date) => {
  const currentDate = new Date();
  const filteredDates = date
    ?.filter((item) => {
      const dateParts = item?.date?.split(" ");
      const year = parseInt(dateParts[2], 10);
      const month = parseInt(dateParts[1], 10); // Month is zero-based
      const day = parseInt(dateParts[0], 10);
      const date = new Date(year, month, day);
      const times = item?.time;

      return date >= currentDate && times && times.length > 0;
    })
    .sort((a, b) => {
      const dateA = new Date(b?.date);
      const dateB = new Date(a?.date);
      return dateA - dateB;
    });

  return filteredDates;
};

function formatTime(ms) {
  const seconds = Math.floor(ms / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  return {
    days: days,
    hours: hours % 24,
    minutes: minutes % 60,
    seconds: seconds % 60,
  };
}

export const _CalculateRemainingTime = (sessiondate, sessiontime) => {
  // Extract meeting date and time
  const meetingTime = sessiontime;
  const meetingDate = sessiondate;

  // Split the date into day, month, and year
  const [day, month, year] = meetingDate.split(" ").map(Number);

  // Split the time into hours, minutes, and period (AM/PM)
  const [time, period] = meetingTime.split(" ");
  let [hours, minutes] = time.split(":").map(Number);

  // Convert hours to 24-hour format if the period is "PM"
  if (period === "PM" && hours < 12) {
    hours += 12;
  }

  // Calculate the meeting date and time in milliseconds since epoch
  const meetingDateTime = new Date(
    year,
    month - 1,
    day,
    hours,
    minutes
  ).getTime();

  // Get the current time in milliseconds since epoch
  const currentTime = new Date().getTime();

  // Calculate the time difference between current time and meeting time
  const timeDifference = meetingDateTime - currentTime;

  // Calculate the passed time if the meeting has already started
  const passedTime = currentTime - meetingDateTime;

  // Determine the status based on the time difference
  if (timeDifference >= 0) {
    // Meeting hasn't started yet
    return {
      hasTime: true,
      meetingHasStarted: false,
      meetingReschedule: false,
      remainingTime: formatTime(Math.abs(timeDifference)),
    };
  } else if (timeDifference >= -45 * 60 * 1000) {
    // Meeting has started less than 45 minutes ago
    console.log("here");
    return {
      hasTime: false,
      meetingHasStarted: true,
      meetingReschedule: false,
      passedTime: formatTime(Math.abs(passedTime)),
    };
  } else {
    // Meeting has started more than 45 minutes ago
    return {
      hasTime: false,
      meetingHasStarted: false,
      meetingReschedule: true,
    };
  }
};

export const _scrolltoElement = (elementname) => {
  let element = document.getElementsByClassName(elementname)[0];
  element.scrollIntoView({
    behavior: "smooth",
  });
};

export const _contentSpliter = (blogContent) => {
  const co = blogContent.split("/*il/");

  if (co.length > 1) {
    return (
      <ul className="flex flex-col items-start my-3">
        {co.map((compo) => (
          <>
            {compo.length ? (
              <li
                className="text-slate-300 text-xl text-justify my-1  font-medium"
                style={{
                  listStyle: "disc",
                }}
              >
                {compo}
              </li>
            ) : (
              ""
            )}
          </>
        ))}
      </ul>
    );
  } else {
    return (
      <div
        className="text-slate-300 text-xl text-justify my-3 font-medium"
        style={{
          lineHeight: "28px",
        }}
      >
        {blogContent}
      </div>
    );
  }
};

export const _getMeetings = (date, sessions) => {};
