import React, { useState } from "react";

function SiteBenefit() {
  const [features, setFeatures] = useState([
    {
      icon: "support_agent",
      title: "Rich Experienced Mentors",
      description:
        "The mentor is typically more experienced or knowledgeable in the area in question and acts as a role model and sounding board for the mentee.",
      backgroundColor: "#ffa200",
    },
    {
      icon: "videocam",
      title: "1:1 Quality Mock Interviews",
      description:
        "The goal of a 1:1 mentorship is to help the mentee learn new skills, gain valuable insights, and achieve their goals.",
      backgroundColor: "#f7dc6f",
    },
    {
      icon: "speed",
      title: "Value of Network and learning Over Money",
      description:
        "A strong network and a focus on learning can also provide a sense of fulfilment and purpose that may be difficult to achieve through the pursuit of money alone.",
      backgroundColor: "#f7dc6f",
    },
    {
      icon: "paid",
      title: "Post-interview In-depth Feedback with suggestions",
      description:
        "Providing post-interview in-depth feedback with suggestions can be a valuable tool for helping job candidates improve their interview skills and increase their chances of success in the future.",
      backgroundColor: "#f7dc6f",
    },
    {
      icon: "groups",
      title: "Network with mentors for future reference",
      description:
        "Networking with mentors can be a valuable tool for building professional connections and gaining valuable guidance and advice.",
      backgroundColor: "#f7dc6f",
    },
    {
      icon: "videocam",
      title: "Backend Support",
      description:
        "We take pride in our robust backend support system. Our dedicated team is always available to assist you with any logistical or administrative matters, ensuring that your mentorship experience is smooth and hassle-free.",
      backgroundColor: "#f7dc6f",
    },
  ]);

  const FeatureCard = ({
    icon,
    title,
    description,
    backgroundColor,
    textColor,
  }) => (
    <div className="py-6 h-64 w-1/3 max-md:w-full max-lg:w-1/2 px-6 border border-black/10">
      <div
        className={`w-12 h-12 text-[black] flex items-center justify-center rounded-md shadow-md`}
      >
        <span className={`material-symbols-outlined ${textColor}`}>{icon}</span>
      </div>
      <div className={`mt-3 text-black/90 font-medium text-xl`}>{title}</div>
      <div className={`text-black/70 text-sm my-2 text-justify`}>
        {description}
      </div>
    </div>
  );
  return (
    <div className="p-3 mt-14">
      <div className="pt-10 pb-6 w-full bg-[#ffffff] rounded-3xl">
        <div className="w-full flex items-center flex-col">
          <div className="text-4xl text-center font-bold text-black max-sm:text-2xl max-md:text-4xl">
            No need to Struggle Alone Anymore
          </div>
          <div className="text-center text-black/60 text-xl mt-2">
            Long term mentorship gets fully covered
          </div>
        </div>
        <div className="py-16 w-full flex items-start flex-wrap px-20 max-lg:px-5 max-lg:pb-3">
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default SiteBenefit;
