import React, { useState } from "react";
import Homebanner from "../Components/Home/Homebanner";
import SiteBenefit from "../Components/Home/SiteBenefit";
import Assessment from "../Components/Home/Assessment";
import Carreer from "../Components/Home/Carreer";
import BlogContainer from "../Components/Home/BlogContainer";
import Footer from "../Components/Common/Footer";
import { Helmet } from "react-helmet";
import { Faq } from "../Data/dataArray";
import ProductShowCase from "../Components/Home/Product/ProductShowCase";
import GradientBackground from "../Assets/images/firebase-gradient.png";
import { _apiCalls, _apiGetCalls } from "../Services/ApiCalls";
import { IsLoading } from "../Containers/Redux/Selectors";
import { useDispatch } from "react-redux";

function Home() {
  const dispatch = useDispatch();
  const isLoading = IsLoading();
  const [program, setProgram] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [education, setEducation] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form reload
    dispatch({ type: "site_Loading", payload: true });
    const formData = {
      program,
      name,
      email,
      phone,
      qualification: education,
    };

    try {
      const request = await _apiCalls("/query", formData);
      dispatch({ type: "site_Loading", payload: false });
    } catch (err) {
      dispatch({ type: "site_Loading", payload: false });
      console.log(err);
    }
    console.log("Captured Data:", formData);
    // You can now send `formData` to an API or use it as needed
  };
  return (
    <div
      className=""
      style={{
        fontFamily: `'Rethink Sans', sans-serif`,
      }}
    >
      <Helmet>
        <title>
          SkillKart: Be Placement-ready with the top industry mentors
        </title>
        <meta name="description" content="" />
        <meta
          property="og:title"
          content="SkillKart: Be Placement-ready with the top industry mentors"
        />
        <meta
          property="og:description"
          content="Skillkart provides Placement interview preparation with 1:1 doubt support, Qualified and Experienced mentors, Resume Building Guidance, and Technical support"
        />
        <meta
          property="og:image"
          content="https://www.skillkart.app/static/media/log.31e5f9e96bf89941fe15.jpeg"
        />
        <meta
          property="twitter:title"
          content="SkillKart: Be Placement-ready with the top industry mentors"
        />
        <meta property="og:type" content="website" />
        <meta
          property="twitter:description"
          content="Skillkart.app | Be mentored and trained with India's largest Mentor community for your dream job."
        />
        <meta name="keywords" content="" />
      </Helmet>
      <Homebanner />
      <div className="pt-28 pb-16 px-16 max-[666px]:px-6 max-sm:px-2 relative">
        {" "}
        <div className="absolute bottom-0 w-full h-full z-0 left-0">
          <img src={GradientBackground} alt="" className="w-full h-full" />
        </div>
        <div className="bg-white/5">
          <div className="flex flex-col items-center px-2 relative">
            <div className="rounded-full text-black text-lg text-center">
              How to start
            </div>
            <div className="text-5xl font-bold my-2 max-lg:w-full">
              Get Started in 3 Easy Steps
            </div>
            <div className="text-black/70 text-xl">
              Follow these three simple steps to get started with Long Term
              Mentorship
            </div>
          </div>
          <div className="flex justify-start max-md:px-0 relative flex-wrap">
            <div className="w-1/2 flex flex-col items-start max-[1104px]:flex-col py-8 relative max-lg:w-full">
              <div className="w-full px-3 max-[1104px]:w-2/3 max-[1104px]:pb-4 max-md:w-full py-3 relative max-lg:w-full">
                <div className="pl-7 max-lg:pl-0 pr-3 max-[1150px]:pb-5 max-[1150px]:h-auto rounded-xl flex items-start justify-center flex-col">
                  <div className="flex items-start justify-center mt-4 max-lg:mt-0 flex-col">
                    <div className="mt-4">
                      <div className="font-bold text-3xl">
                        Choose Your Program
                      </div>
                    </div>
                  </div>
                  <div className="mx-1 mt-2 text-sm">
                    Explore our range of comprehensive programs tailored to suit
                    various academic backgrounds and career aspirations,
                    including B.Tech, MBA, Digital Marketing, and more.
                  </div>
                </div>
              </div>
              <div className="w-full px-3 py-3 max-[1104px]:w-2/3 max-[1104px]:pb-4 max-md:w-full ">
                <div className="pl-7 max-lg:pl-0 pr-3 max-[1150px]:pb-5 max-[1150px]:h-auto rounded-xl flex items-start justify-center flex-col">
                  <div className="flex items-start mt-4 max-lg:mt-0  flex-col">
                    <div className="mt-4 max-lg:mt-0 ">
                      <div className="font-bold text-3xl">
                        Shortlist Your Preferred Mentor
                      </div>
                    </div>
                  </div>
                  <div className="mx-1 mt-2 text-sm">
                    Browse through our list of experienced mentors and select
                    the one that best fits your preferences and career goals
                  </div>
                </div>
              </div>
              <div className="w-full py-3 px-3 max-[1104px]:w-2/3 max-[1104px]:pb-4 max-md:w-full">
                <div className="pl-7 max-lg:pl-0 pr-3 max-[1150px]:pb-5 max-[1150px]:h-auto rounded-xl flex items-start justify-center flex-col">
                  <div className="flex items-start mt-4 flex-col max-lg:mt-0 ">
                    <div className="mt-4 max-lg:mt-0 ">
                      <div className="font-bold text-3xl">
                        Begin Your One-on-One Mentorship
                      </div>
                    </div>
                  </div>
                  <div className="mx-1 mt-2 text-sm">
                    Embark on a personalized and long-term mentorship journey
                    with your selected mentor. Receive tailored guidance,
                    practice sessions, and valuable insights to excel in your
                    interview preparation.
                  </div>
                </div>
              </div>
            </div>
            <div className="w-1/2 flex items-center px-24 py-14 max-lg:w-full max-lg:px-0 max-lg:py-3">
              <div className=" w-full rounded-lg p-5 bg-[#e6e6e6]">
                <div className="w-full my-3 bg-white h-14 rounded-lg">
                  <input
                    type="text"
                    className="w-full bg-transparent h-full px-4 text-base outline-none"
                    placeholder="Program Interested"
                    value={program}
                    onChange={(e) => setProgram(e.target.value)}
                  />
                </div>
                <div className="w-full my-3 bg-white h-14 rounded-lg">
                  <input
                    type="text"
                    className="w-full bg-transparent py-2 h-full px-4 outline-none"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="w-full my-3 bg-white h-14 rounded-lg">
                  <input
                    type="text"
                    className="w-full bg-transparent h-full px-4 outline-none"
                    placeholder="Enter email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="w-full my-3 bg-white h-12 rounded-lg">
                  <input
                    type="text"
                    className="w-full bg-transparent h-full px-4"
                    placeholder="Enter Phone Number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                <div className="w-full my-3 bg-white h-12 rounded-lg">
                  <input
                    type="text"
                    className="w-full bg-transparent h-full px-4"
                    placeholder="Education Qualification (Eg, B.Tech)"
                    value={education}
                    onChange={(e) => setEducation(e.target.value)}
                  />
                </div>
                {isLoading ? (
                  <div className="w-full">
                    <div className="flex items-center rounded-md justify-center font-semibold cursor-pointer h-12  text-black">
                      <div className="w-6 rounded-full h-6 border-t-2 border-2 border-solid border-t-black loadingspinner mr-6"></div>
                      Sending data
                    </div>
                  </div>
                ) : (
                  <div
                    onClick={handleSubmit}
                    className="w-full cursor-pointer flex items-center justify-center bg-black text-[#f4d03f] h-14 text-lg font-semibold rounded-lg"
                  >
                    Get a Call Back
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProductShowCase />
      <SiteBenefit />
      <Assessment />
      {/* <Carreer /> */}
      <BlogContainer />
      <div></div>
      <div className="w-full flex items-center flex-col py-20 max-md:px-3">
        <div className="text-black text-5xl font-bold">FAQs</div>
        <div className="w-2/3 mt-16 max-lg:w-4/5 max-md:w-full ">
          {Faq.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;

const FAQItem = ({ question, answer }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="py-3 border border-black/20 my-4 px-6 shadow rounded-md max-lg:py-1 ">
      <div
        className="flex items-center justify-between py-3 "
        onClick={toggleExpand}
      >
        <h3 className="text-2xl font-medium max-lg:text-xl ">{question}</h3>
        <span className={`icon ${expanded ? "open" : "closed"} `}>&#9660;</span>
      </div>
      {expanded && <p className="answer text-base max-md:pb-4">{answer}</p>}
    </div>
  );
};
